import { Typography } from "@mui/material";
import { fileAssets, fileExternalLinks } from "../../../config";

function MSIPage() {
  return (
    <>
      <Typography variant="h3">Maritime Sector Incentive (MSI)</Typography>
      <br />
      <Typography variant="h4">Pool Operations</Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        For guidelines on shipping pool operations, please{" "}
        <a
          href={fileExternalLinks.POOL_OPERATION_PATH}
          target="_blank"
          rel="noreferrer noopener"
        >
          click here.
        </a>
      </Typography>
      <br />
      <Typography variant="h4">Tax Exemption</Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        For tax exemption on vessel disposal gains, please{" "}
        <a
          href={fileExternalLinks.TAX_EXEMPTION_VESSEL_DISPOSAL_PATH}
          target="_blank"
          rel="noreferrer noopener"
        >
          click here.
        </a>
      </Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        For tax exemption on in-house ship management fees, please{" "}
        <a
          href={fileExternalLinks.TAX_EXEMPTION_SHIP_MANAGEMENT_PATH}
          target="_blank"
          rel="noreferrer noopener"
        >
          click here.
        </a>
      </Typography>
      <br />
      <Typography variant="h4">Alternative Net Tonnage Basis of Taxation</Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        For alternative net tonnage basis of taxation, please{" "}
        <a
          href={fileAssets.FAQ_NET_TONNAGE_PDF_PATH}
          target="_blank"
          rel="noreferrer noopener"
        >
          click here.
        </a>
      </Typography>
      <br />
      <Typography variant="h4">Minimum Commitments</Typography>
      <br />
      <Typography className="univers-55roman-normal-scarpa-flow-16px">
        For minimum commitments under the MSI, please{" "}
        <a
          href={fileExternalLinks.MINIMUM_COMMITMENTS_PATH}
          target="_blank"
          rel="noreferrer noopener"
        >
          click here.
        </a>
      </Typography>
    </>
  );
}

export default MSIPage;
