export const config = {
    environment: process.env.REACT_APP_ENVIRONMENT,
    appURL : process.env.REACT_APP_APPURL,
    appFormBaseURL: process.env.REACT_APP_APPFORMBASEURL,
    spcpReturnUrl: process.env.REACT_APP_SPCPRETURNURL,
    cseID: process.env.REACT_APP_CSEID,
    deploymentDate: process.env.REACT_APP_DEPLOYMENTDATE,
    intervalPopupBeforeExpired: process.env.REACT_APP_INTERVALPOPUPBEFOREEXPIRED,
    baseURL: process.env.REACT_APP_BASEURL,
    vesselUploadInputSampleURL: process.env.REACT_APP_VESSEL_UPLOAD_INPUT_SAMPLE_DOWNLOAD_URL,
    form_name_target_ship_main: process.env.REACT_APP_FORMNAME_TARGET_SHIP_MAIN,
    form_name_target_ship_cr: process.env.REACT_APP_FORMNAME_TARGET_SHIP_CR,
    form_name_target_ship_mof: process.env.REACT_APP_FORMNAME_TARGET_SHIP_MOF,
    form_name_target_container_main: process.env.REACT_APP_FORMNAME_TARGET_CONTAINER_MAIN,
    form_name_target_container_cr: process.env.REACT_APP_FORMNAME_TARGET_CONTAINER_CR,
    form_name_target_container_mof: process.env.REACT_APP_FORMNAME_TARGET_CONTAINER_MOF,
    form_name_target_idp_main: process.env.REACT_APP_FORMNAME_TARGET_IDP_MAIN,
    form_name_target_idp_cr: process.env.REACT_APP_FORMNAME_TARGET_IDP_CR,
    form_name_target_idp_claim: process.env.REACT_APP_FORMNAME_TARGET_IDP_CLAIM,
    form_name_target_msi_main: process.env.REACT_APP_FORMNAME_TARGET_MSI_MAIN,
    form_name_original_ship_main: process.env.REACT_APP_FORMNAME_ORIGINAL_SHIP_MAIN,
    form_name_original_ship_cr: process.env.REACT_APP_FORMNAME_ORIGINAL_SHIP_CR,
    form_name_original_ship_mof: process.env.REACT_APP_FORMNAME_ORIGINAL_SHIP_MOF,
    form_name_original_container_main: process.env.REACT_APP_FORMNAME_ORIGINAL_CONTAINER_MAIN,
    form_name_original_container_cr: process.env.REACT_APP_FORMNAME_ORIGINAL_CONTAINER_CR,
    form_name_original_container_mof: process.env.REACT_APP_FORMNAME_ORIGINAL_CONTAINER_MOF,
    form_name_original_idp_main: process.env.REACT_APP_FORMNAME_ORIGINAL_IDP_MAIN,
    form_name_original_idp_cr: process.env.REACT_APP_FORMNAME_ORIGINAL_IDP_CR,
    form_name_original_idp_claim: process.env.REACT_APP_FORMNAME_ORIGINAL_IDP_CLAIM,
    form_name_original_msi_main: process.env.REACT_APP_FORMNAME_ORIGINAL_MSI_MAIN,
    wogaaURL: process.env.REACT_APP_WOGAAURL,
    isIDP: process.env.REACT_APP_IDP,
    logoURL: process.env.REACT_APP_LOGOURL,
    contactUsURL: process.env.REACT_APP_CONTACTUSURL
}

export const fileAssets = {
    FAQ_NET_TONNAGE_PDF_PATH: process.env.PUBLIC_URL + '/docs/omp-documents-files/faq-on-ntt-basis.pdf',
}

export const fileExternalLinks = {
    POOL_OPERATION_PATH: 'https://www.mpa.gov.sg/docs/mpalibraries/mpa-documents-files/business-capability-development-division/general-guidelines-for-pool-operations.pdf?sfvrsn=5727a330_0',
    TAX_EXEMPTION_VESSEL_DISPOSAL_PATH: 'https://www.mpa.gov.sg/docs/mpalibraries/mpa-documents-files/business-capability-development-division/faq-msi-tax-exemption-of-vessel-disposal-gains-jan2022.pdf?sfvrsn=cfbf8726_0',
    TAX_EXEMPTION_SHIP_MANAGEMENT_PATH: 'https://www.mpa.gov.sg/docs/mpalibraries/mpa-documents-files/business-capability-development-division/faq-msi-tax-exemption-on-in-house-ship-management-fees-jan2022.pdf?sfvrsn=2e742c49_0',
    MINIMUM_COMMITMENTS_PATH: 'https://www.mpa.gov.sg/docs/mpalibraries/mpa-documents-files/business-capability-development-division/faq-msi-on-minimum-commitments-under-msi.pdf?sfvrsn=912e2d37_0',
}