import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  Pagination,
  TablePagination,
  Typography,
} from "@mui/material";
import { IconArrowLeft, IconPencil, IconTrash } from "@tabler/icons";
import { getPublicUserListingAPIAsync } from "../../Redux/PublicUserListing/PublicUserListingSlice";
import { deletePublicUserListAPIAsync } from "../../Redux/PublicUserListing/PublicUserSlice";
import { PublicUserDeleteDialog } from "./PublicUserUpdateDialog";
import Paper from "@mui/material/Paper";
import StatusChip from "../ApplicationForm/StatusChip";
import loginHelper from "../../Common/loginHelper";
import "./GlobalCSS.css";
import { TablePaginationWithSelectBoxAndSelectIcon } from "../../Common/CommonUtils";

export default function PublicUserListing() {
  const [validationDialog, setValidationDialog] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const rows = useSelector((state) => state.publicUserList.publicUserListing);
  const [startSpinner, setStartSpinner] = useState(false);

  useEffect(() => {
    let loginUser = loginHelper.getLoginUser();
    let role = loginUser.CorppassRole;
    if (role.toUpperCase() !== "ADMIN") {
      alert("Unauthorised user detected.");
      history.push("/Dashboard");
    }

    setStartSpinner(true);
    dispatch(getPublicUserListingAPIAsync())
      .unwrap()
      .then(() => {
        setStartSpinner(false);
      })
      .catch(() => {
        setStartSpinner(false);
      });
    window.scrollTo(0, 0);
  }, [dispatch]);

  const columns = [
    {
      field: "Name",
      headerName: "NAME",
      headerClassName: "univers-55roman-normal-mine-shaft-14px",
      align: "left",
      flex: 1.2,
      sortable: false,
      cellClassName: "univers-55roman-normal-mine-shaft-16px",
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            {params.row.Name}
          </div>
        );
      },
    },
    {
      field: "Schemes",
      headerName: "SCHEMES",
      headerClassName: "univers-55roman-normal-mine-shaft-14px",
      align: "left",
      flex: 1.2,
      cellClassName: "univers-55roman-normal-mine-shaft-16px",
      renderCell: (params) => params.row.Schemes,
    },
    {
      field: "isActive",
      headerName: "STATUS",
      headerClassName: "univers-55roman-normal-mine-shaft-14px",
      align: "left",
      flex: 1.2,
      sortable: false,
      cellClassName: "univers-55roman-normal-mine-shaft-16px",
      renderCell: (params) => (
        <div
          style={{
            height: "33px",
            display: "inline-flex",
            verticalAlign: "top",
            alignItems: "center",
          }}
        >
          <StatusChip type={String(params.row.isActive)} />
        </div>
      ),
    },
    {
      field: "action",
      headerName: "ACTIONS",
      headerClassName: "univers-55roman-normal-mine-shaft-14px",
      headerAlign: "center",
      align: "center",
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <div className="nested-listing">
          <Grid container spacing={2}>
            <Grid item xs={6}>
            {params.row.CorppassRole.toUpperCase() !== "ADMIN" && (
            <IconButton
              sx={{ cursor: "pointer" }}
              onClick={(e) => {
                handleMenuItemClick(params.row.Id, 3);
              }}
            >
              <IconTrash />
            </IconButton>
          )}
            </Grid>
            <Grid item xs={6}>
            <IconButton
            sx={{ cursor: "pointer" }}
            onClick={(e) => handleMenuItemClick(params.row.Id, 1)}
          >
            <IconPencil />
          </IconButton>
            </Grid>
          </Grid>
        </div>
      ),
    },
  ];


  const handleMenuItemClick = (id, index) => {
    switch (index) {
      case 0:
        break;
      case 1:
        history.push({
          pathname: "/AddUserView",
          state: {
            userid: id,
          }
        });
        break;
      case 2:
        break;
      case 3:
        setUserId(id);
        setValidationDialog(true);
        break;
      default:
    }
  };

  const [userId, setUserId] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage(newPage - 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDialog = async (text, id) => {
    if (text === "Yes") {
      setStartSpinner(true);
      dispatch(deletePublicUserListAPIAsync(id))
        .unwrap()
        .then((responseStatus) => {
          setStartSpinner(false);
          if (responseStatus.status === 200) {
            alert("User successfully deleted.");
          } else {
            alert("Unable to process your request. Please try again later.");
          }
          window.location.reload(false);
        })
        .catch(() => {
          setStartSpinner(false);
          alert("Unable to process your request. Please try again later.");
        });
    }
  };

  return (
    <>
      <div className="background">
        <Grid container>
          {/* Header and Subheader */}
          <Container sx={{ pt: 3, pb: 5 }}>
            <Button
              onClick={() => history.push("/Dashboard")}
              className="nav-back-btn"
            >
              <IconArrowLeft color="#666" />
            </Button>
            <Breadcrumbs
              sx={{ display: "inline-block", verticalAlign: "middle" }}
              aria-label="breadcrumb"
            >
              <Link
                underline="hover"
                onClick={() => history.push("/Dashboard")}
                mx={1}
                className="active-link univers-65-bold-scarpa-flow-12px"
              >
                Dashboard
              </Link>
              <Link
                underline="none"
                mx={1}
                className="disabled-link univers-65-bold-scarpa-flow-12px"
              >
                Users Management
              </Link>
            </Breadcrumbs>
            <br />
            <br />
            <div style={{ display: "inline-block" }}>
              <Typography variant="h2">Users Management</Typography>
              <Typography className="line-height-32px univers-55roman-normal-mine-shaft-22px">
                You can manage your users and assign schemes here.
              </Typography>
            </div>
          </Container>
        </Grid>

        <Container sx={{ pb: 2.5 }}>
          <Paper className="tab-mainMA" elevation={0}>
            <Container sx={{ px: 3, mt: 3 }}>
              <DataGrid
                pageSize={rowsPerPage}
                page={page}
                headerHeight={47}
                autoHeight={false}
                style={{ height: "649px" }}
                rowHeight={60}
                rows={rows}
                getRowId={(row) => row.Id}
                columns={columns}
                disableColumnMenu={true}
                loading={startSpinner}
                onCellClick={(e) => {
                  setUserId(e.row.Id);
                }}
                getRowClassName={(params) => {
                  if (params.row.status === "Warning") {
                    return "row-warning";
                  }
                }}
                hideFooter
                components={{}}
              />
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  px: 2,
                  height: 80,
                }}
              >
                <TablePagination
                  rowsPerPageOptions={[10, 25, 50, 100]}
                  component="div"
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={"Entries"}
                  sx={TablePaginationWithSelectBoxAndSelectIcon()}
                />
                <Pagination
                  onChange={handleChangePage2}
                  page={page + 1}
                  count={Math.ceil(rows.length / rowsPerPage)}
                  sx={{
                    "& .MuiPaginationItem-circular.Mui-selected": {
                      backgroundColor: "#0065BD",
                      color: "white",
                    },
                  }}
                />
              </Grid>
            </Container>
          </Paper>
        </Container>
        <PublicUserDeleteDialog
          open={validationDialog}
          setOpen={setValidationDialog}
          onClickDialogListener={handleDialog}
          userId={userId}
        />
      </div>
    </>
  );
}
